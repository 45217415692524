<template>
  <div class="sleep">
    <sleep-device-info :deviceDetail="deviceDetail" :reportCount="reportArray.length"
      @onButtonClick="onButtonClick"></sleep-device-info>
    <div class="sleep-line"></div>
    <div class="sleep-bottom">
      <div class="sleep-bottom-top">
        <p class="sleep-bottom-top-title">检测报告：</p>
        <p class="sleep-bottom-top-value">{{ total }}份</p>
      </div>
      <list-title-bar></list-title-bar>
      <cell v-for="(item, index) of reportArray" :key="index" :model="item" :isSelected="selectedID === item.id"
        @onSelectClick="onCellSelectClick"></cell>
    </div>
    <unbind-alert v-if="isShowUnbindAlert" :model="unbindAlertModel" @onUnbindSucceedClick="onUnbindSucceedClick"
      @onCloseClick="onUnbindCloseClick"></unbind-alert>
    <bind-alert v-if="isShowBindAlert" :initSelectedPatient="patientDetailModel" @onBindSucceedClick="onBindSucceedClick"
      @onCloseClick="onBindCloseClick"></bind-alert>
  </div>
</template>

<script>
import SleepDeviceInfo from "@c/user-main/sleep/sleep-device-info.vue";
import ListTitleBar from "@c/main/patient/sleep-list/sleep-title-bar.vue";
import Cell from "@c/main/patient/sleep-list/sleep-cell.vue";
import { DateTool } from "@js/date-tool.js";
import EventBus from "@js/event-bus.js";
export default {
  components: {
    SleepDeviceInfo,
    ListTitleBar,
    Cell,
    BindAlert: () =>
      import(
        "@c/common/alert/panel-alert/panel-alert-bind-sleep-machine.vue"
      ),
    UnbindAlert: () =>
      import(
        "@c/common/alert/panel-alert/panel-alert-unbind-sleep-machine.vue"
      ),
  },

  props: {
    patientDetailModel: Object,
  },

  data() {
    return {
      //设备信息
      deviceDetail: undefined,
      //报告列表
      reportArray: [],
      total: 0,
      currentPage: 1,
      selectedID: 0,
      //弹窗
      unbindAlertModel: undefined,
      isShowUnbindAlert: false,
      isShowBindAlert: false
    }
  },

  watch: {
    patientDetailModel() {
      const sleepDeviceId = this.patientDetailModel.model.sleepDeviceId
      this.getSleepDeviceDetail(sleepDeviceId);
      this.getSleepReportList();
    },
  },

  mounted() {
    const sleepDeviceId = this.patientDetailModel.model.sleepDeviceId
    this.getSleepDeviceDetail(sleepDeviceId);
    this.getSleepReportList();
  },

  methods: {
    onButtonClick(buttonTitle) {
      switch (buttonTitle) {
        case "设备解绑":
          this.unbindAlertModel = {
            name: this.patientDetailModel.name,
            gender: this.patientDetailModel.gender,
            mobile: this.patientDetailModel.mobile,
            suid: this.patientDetailModel.model.suid,
            sn: placeholderText(this.deviceDetail.sn),
            start: this.deviceDetail.lendTime,
            end: DateTool.dateToStr(new Date()),
            hospitalId: this.patientDetailModel.model.hospitalId
          };
          this.isShowUnbindAlert = true;
          break;
        case "设备绑定":
          this.isShowBindAlert = true;
          break;
        default:
          break;
      }
    },

    onCellSelectClick(model) {
      this.selectedID = model.id;
    },

    //用户解绑
    onUnbindCloseClick() {
      this.isShowUnbindAlert = false;
    },

    onUnbindSucceedClick() {
      this.$toast.showGreen("设备解绑成功");
      this.isShowUnbindAlert = false;
      EventBus.$emit("onPatientInfoChanged")
    },

    //绑定用户
    onBindCloseClick() {
      this.isShowBindAlert = false;
    },

    onBindSucceedClick(_, deviceID) {
      this.$toast.showGreen("设备绑定成功");
      this.isShowBindAlert = false;
      EventBus.$emit("onPatientInfoChanged")
    },

    async getSleepDeviceDetail(sleepDeviceId) {
      if (!sleepDeviceId) {
        this.deviceDetail = undefined
      } else {
        try {
          const params = {
            id: sleepDeviceId
          };
          const data = await this.$api.getSleepDeviceDetail(params);
          this.deviceDetail = data;
        } catch (error) {
          this.$toast.showRed(error);
        }
      }
    },

    async getSleepReportList() {
      if (!this.patientDetailModel) {
        return;
      }
      try {
        const params = {
          uid: this.patientDetailModel.model.suid,
          hospitalId: this.patientDetailModel.model.hospitalId,
        };
        const data = await this.$api.getSleepReportList(params);
        this.reportArray = data;
        this.total = this.reportArray.length;
      } catch (error) {

      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sleep {
  display: flex;
  flex-direction: column;

  &-line {
    background-color: #e8e8e8;
    height: 1px;
    margin-top: 22px;
  }

  &-bottom {
    box-sizing: border-box;
    padding: 0px 11px 180px;

    &-top {
      color: #2e6be6;
      font-size: 14px;
      height: 60px;
      display: flex;
      align-items: center;
    }
  }
}
</style>