import { DateTool } from "@js/date-tool.js";
import { findKey } from "@js/object-tool.js";
class SleepDeviceDetailModel {
    // constructor
    constructor(model) {
        this.model = model
        if (this.model) {
            this.buttonTitle = "设备解绑"
            this.sn = placeholderText(this.model.sn)
            this.borrowDate = this._initBorrowDate()
            this.monitorDuration = placeholderText(this.model.monitor)
            this.borrowDayCount = this._initBorrowDayCount()
            this.outDayCount = this.model.outTime
            this.workMode = placeholderText(findKey(SLEEP_WORK_MODE, parseInt(this.model.modeType)))
            this.ringMonitorStatus = this._initRingMonitorStatus()
            this.heartRate = this._initHeart()
            this.breath = this._initBreath()
            this.oxygenBlood = this._initBlood()
            this.temperature = this._initTemperature()
            this.isShowRingInfo = this._initIsShowRingStatus()
            this.ringBatteryIcon = this._initRingBatteryIcon()
            this.ringPower = placeholderText(`${this.model.battery}`, "%")
            this.ringChargeStatus = this._initRingChargeStatus()
            this.ringSN = placeholderText(this.model.ringSn)
        } else {
            this.buttonTitle = "设备绑定"
            this.sn = placeholderText(null)
            this.borrowDate = placeholderText(null)
            this.monitorDuration = placeholderText(null)
            this.borrowDayCount = placeholderText(null)
            this.outDayCount = null
            this.workMode = placeholderText(null)
            this.ringMonitorStatus = placeholderText(null)
            this.heartRate = placeholderText(null)
            this.breath = placeholderText(null)
            this.oxygenBlood = placeholderText(null)
            this.temperature = placeholderText(null)
            this.isShowRingInfo = false
            this.ringBatteryIcon = placeholderText(null)
            this.ringPower = placeholderText(null)
            this.ringChargeStatus = placeholderText(null)
            this.ringSN = placeholderText(null)
        }

    }

    get isMonitorConnected() {
        return this.model.deviceConnectStatus && this.model.deviceConnectStatus != SLEEP_MONITOR_STATUS.未连接
    }

    get hasRingSN() {
        return !!this.model.ringSn
    }

    // init
    _initBorrowDate() {
        return placeholderText(DateTool.timeStrToStr(this.model.lendTime,"YYYY年MM月DD日"))
    }

    _initBorrowDayCount() {
        return `${this.model.frequency}天`
    }

    _initRingMonitorStatus() {
        if (!this.hasRingSN) { return placeholderText(null) }
        if (!this.isMonitorConnected) { return placeholderText(null) }
        switch (this.model.connectStatus) {
            case null:
                return placeholderText(null)
            case SLEEP_RING_CONNECT_STATUS.已连接:
                return "已连接"
            default:
                return "未连接"
        }
    }

    _initHeart() {
        if (!this.isMonitorConnected) { return placeholderText(null) }
        return placeholderText(this.processParticularText("heart", this.model.heartRate), "bpm")
    }

    _initBlood() {
        if (!this.isMonitorConnected) { return placeholderText(null) }
        return placeholderText(this.processParticularText("blood", this.model.bloodOxygen), "%")
    }

    _initBreath() {
        if (!this.isMonitorConnected) { return placeholderText(null) }
        return placeholderText(this.processParticularText("breath", this.model.breathRate, "bpm"))
    }

    _initTemperature() {
        if (!this.isMonitorConnected) { return placeholderText(null) }
        return placeholderText(this.processParticularText("temperature", this.model.tempeature), '℃')
    }

    _initIsShowRingStatus() {
        return this.isMonitorConnected && this.model.connectStatus != SLEEP_RING_CONNECT_STATUS.未连接
    }

    _initRingBatteryIcon() {
        const battery = this.model.battery
        switch (true) {
            case battery >= 0 && battery < 25:
                return require("@imgs/user-main-battery-0.png");
            case battery >= 25 && battery < 50:
                return require("@imgs/user-main-battery-1.png");
            case battery >= 50 && battery < 75:
                return require("@imgs/user-main-battery-2.png");
            default:
                return require("@imgs/user-main-battery-3.png");
        }
    }

    _initRingChargeStatus() {
        return this.model.powerStatus == SLEEP_RING_CHARGE_STATUS.正在充电 ? "充电中" : ""
    }

    processParticularText(title, value) {
        const valueNumber = this.convertToNumber(value)
        switch (title) {
            case "blood":
                return (isNaN(valueNumber) || valueNumber <= 0 || valueNumber == 37) ? undefined : valueNumber
            case "heart":
            case "breath":
                return (isNaN(valueNumber) || valueNumber <= 0) ? undefined : valueNumber
            case "temperature":
                return (isNaN(valueNumber) || valueNumber == 0) ? undefined : valueNumber
            default:
                undefined;
        }
    }

    convertToNumber(str) {
        if (str) {
            return +str
        } else {
            return NaN
        }
    }

}

export { SleepDeviceDetailModel };
