<template>
  <div class="sleep-device-info" v-if="deviceDetailModel">
    <div class="sleep-device-info-left">
      <div class="sleep-device-info-left-left">
        <unit class="sleep-device-info-unit" left="设备序列号" :right="deviceDetailModel.sn"></unit>
        <unit class="sleep-device-info-unit" left="借出日期" :right="borrowInfo"></unit>
        <unit class="sleep-device-info-unit" left="监测时间" :right="deviceDetailModel.monitorDuration"></unit>
      </div>
      <div>
        <button class="sleep-device-info-button" @click="onButtonClick">
          {{ deviceDetailModel.buttonTitle }}
        </button>
        <unit class="sleep-device-info-unit" left="借出天数" :right="deviceDetailModel.borrowDayCount">
          <template v-slot:tail>
            <p v-if="deviceDetailModel.outDayCount" class="sleep-device-info-slot-tail">
              （<span class="sleep-device-info-slot-tail-content">逾期{{ deviceDetailModel.outDayCount }}天</span>）
            </p>
          </template>
        </unit>
        <unit class="sleep-device-info-unit" left="工作模式" :right="deviceDetailModel.workMode"></unit>
      </div>
    </div>
    <div class="sleep-device-info-right">
      <div>
        <unit class="sleep-device-info-unit" left="监测状态" :right="deviceDetailModel.ringMonitorStatus"></unit>
        <div class="sleep-device-info-right-row">
          <unit class="sleep-device-info-unit" left="心率" :right="deviceDetailModel.heartRate"></unit>
          <unit class="sleep-device-info-unit sleep-device-info-right-row-second" left="呼吸"
            :right="deviceDetailModel.breath"></unit>
        </div>
        <div class="sleep-device-info-right-row">
          <unit class="sleep-device-info-unit" left="血氧" :right="deviceDetailModel.oxygenBlood"></unit>
          <unit class="sleep-device-info-unit sleep-device-info-right-row-second" left="室温"
            :right="deviceDetailModel.temperature"></unit>
        </div>
        <div class="sleep-device-info-right-row">
          <unit class="sleep-device-info-unit" left="戒指" :right="deviceDetailModel.isShowRingInfo
            ? `${deviceDetailModel.ringPower}  ${deviceDetailModel.ringChargeStatus}`
            : `-`
            ">
            <template v-slot:middle>
              <div v-if="deviceDetailModel.isShowRingInfo" class="sleep-device-info-slot-middle">
                <img class="sleep-device-info-slot-middle-battery" :src="deviceDetailModel.ringBatteryIcon" />
              </div>
            </template>
          </unit>
          <unit class="
              sleep-device-info-unit
              sleep-device-info-unit
              sleep-device-info-right-row-second
            " left="戒指SN" :right="deviceDetailModel.ringSN">
          </unit>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Unit from "@c/user-main/common/unit.vue";
import { SleepDeviceDetailModel } from "@js/model/sleep-device-detail-model.js";

export default {
  components: { Unit },

  props: {
    deviceDetail: Object,
    reportCount: Number
  },

  computed: {
    deviceDetailModel() {
      return new SleepDeviceDetailModel(this.deviceDetail);
    },

    borrowInfo() {
      if (this.deviceDetail) {
        const borrowDate = this.deviceDetailModel.borrowDate;
        return `${borrowDate}（产生${this.reportCount}份报告）`;
      } else {
        return placeholderText(null);
      }
    },
  },

  methods: {
    onButtonClick() {
      this.$emit("onButtonClick", this.deviceDetailModel.buttonTitle);
    },
  },
};
</script>

<style lang="scss" scoped>
.sleep-device-info {
  height: 345px;
  display: flex;

  &-left {
    border-right: 2px dashed #e8e8e8;
    box-sizing: border-box;
    width: 50%;
    padding: 45px 11px;
    display: flex;

    &-left {
      width: 60%;
      display: flex;
      flex-direction: column;
    }
  }

  &-right {
    box-sizing: border-box;
    width: 50%;
    padding: 44px 66px 66px 66px;

    &-row {
      position: relative;
      display: flex;

      &-second {
        position: absolute;
        left: 260px;
      }
    }
  }

  &-unit {
    height: 30px;
    margin-bottom: 10px;
  }

  &-button {
    position: relative;
    top: 8px;
    color: black;
    border: 1px solid #4382fc;
    border-radius: 4px;
    width: 204px;
    height: 30px;
    margin-bottom: 28px;
  }

  &-slot {
    &-tail {
      color: black;
      font-weight: 300;

      &-content {
        color: #f45c50;
      }
    }

    &-middle {
      margin-left: 15px;
      margin-top: 1px;

      &-battery {
        width: 26px;
        height: 12px;
      }
    }
  }
}
</style>